<template>
	<Modal
		title="查看用户"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="viewData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<!--用户名-->
				<Col span="12">
					<FormItem label="用户名">
						{{ viewData.username || "--" }}
					</FormItem>
				</Col>
				<!--真实姓名-->
				<Col span="12">
					<FormItem label="真实姓名">
						{{ viewData.realName || "--" }}
					</FormItem>
				</Col>
				<!--手机号码-->
				<Col span="12">
					<FormItem label="手机号码">
						{{ viewData.phone || "--" }}
					</FormItem>
				</Col>
				<!--组织-->
				<Col span="12">
					<FormItem label="组织">
						{{ viewData.orgName || "--" }}
					</FormItem>
				</Col>
				<!--部门-->
				<Col span="12">
					<FormItem label="部门">
						{{ viewData.deptName || "--" }}
					</FormItem>
				</Col>
				<!--职称-->
				<Col span="12">
					<FormItem label="职称">
						{{ viewData.jobName || "--" }}
					</FormItem>
				</Col>
				<!--性别-->
				<Col span="12">
					<FormItem label="性别">
						{{ viewData.sexName || "--" }}
					</FormItem>
				</Col>
				<!--微信-->
				<Col span="12">
					<FormItem label="微信">
						{{ viewData.wx || "--" }}
					</FormItem>
				</Col>
				<!--ca编号-->
				<Col span="12">
					<FormItem label="CA编号">
						{{ viewData.caUuid || "--" }}
					</FormItem>
				</Col>
				<!--工号-->
				<Col span="12">
					<FormItem label="工号">
						{{ viewData.jobNumber || "--" }}
					</FormItem>
				</Col>
				<!--更新人-->
				<Col span="12">
					<FormItem label="更新人">
						{{ viewData.updatedBy }}
					</FormItem>
				</Col>
				<!--更新时间-->
				<Col span="12">
					<FormItem label="更新时间">
						{{ viewData.updatedTime }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem prop="identityBack" label="身份证头像面">
						<img
							:src="getPreviewImg(viewData.identityBack)"
							class="preview"
							v-if="viewData.identityBack"
						/>
						<span v-else>--</span>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem prop="identityFront" label="身份证国徽面">
						<img
							:src="getPreviewImg(viewData.identityFront)"
							class="preview"
							v-if="viewData.identityFront"
						/>
						<span v-else>--</span>
					</FormItem>
				</Col>
				<Col span="24" v-if="isIdentityRequired">
					<FormItem prop="businessProxy" label="单位委托书">
						<img
							:src="getPreviewImg(viewData.businessProxy)"
							class="preview"
							v-if="viewData.businessProxy"
						/>
						<span v-else>--</span>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/setting/staff"

const { apiGet } = api
export default {
	name: "ViewDetail",
	props: ["id", "visible", "organizationList"],
	data() {
		return {
			viewData: {},
			loading: false,
			isIdentityRequired: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.viewData = {}
				if (this.id) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.id })
						if (res) {
							this.isIdentityRequired =
								["101", "102", "103"].indexOf(res.data.orgType) > -1
							this.viewData = {
								...this.viewData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	}
}
</script>

<style scoped>
.preview {
	max-width: 100%;
}
</style>
